<template>
  <div
    class="not-found h-half-screen flex flex-col items-center justify-center"
  >
    <div class="my-24 text-center flex items-center">
      <h1 class="text-8xl font-bold pr-8 border-r-2">404</h1>
      <p class="text-2xl pl-8 m-0">Siden du søger eksisterer ikke</p>
    </div>
    <router-link to="/" class="text-black">Gå til forside</router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
